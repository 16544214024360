import React from "react";
import { View, Text, Pressable, StyleSheet, Image } from "react-native";

const UserListItem = ({ user, onPress }) => {
  return (
    <Pressable style={styles.container} onPress={onPress}>
      <Image source={{ uri: user.profilePhoto }} style={styles.profileImage} />
      <View style={styles.userInfo}>
        <View style={styles.usernameContainer}>
          <Text style={styles.username}>{user.username}</Text>
          {user.verified && (
            <Image
              source={require("../assets/tick-mark.png")}
              style={styles.verifiedIcon}
            />
          )}
        </View>
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: "lightgray",
  },
  profileImage: {
    width: 30,
    height: 30,
    borderRadius: 25,
    marginRight: 10,
  },
  userInfo: {
    flex: 1,
  },
  usernameContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  username: {
    fontSize: 16,
    fontWeight: "bold",
  },
  verifiedIcon: {
    width: 16,
    height: 16,
    marginLeft: 5,
  },
});

export default UserListItem;
