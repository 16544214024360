import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  TextInput,
  Pressable,
  ActivityIndicator,
  Image,
  Dimensions,
} from "react-native";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../components/authentication/Firebase";
import { motion } from "framer-motion";
import Navbar from "../../Navbar";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const navigate = useNavigate();
  const { width } = Dimensions.get("window");
  const isTablet = width >= 768; // Assume tablets for widths of 768 or greater

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const isButtonDisabled = !email || !password;
  const [isLoading, setIsLoading] = useState(false);
  const [isBlur, setIsBlur] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    document.title = "Login";
  }, []);

  const handleLogin = async () => {
    if (!email || !password) return;

    setIsLoading(true);
    setIsBlur(true);

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      const user = userCredential.user;
      console.log("Successfully logged in:", user);
      navigate("/home");

      setIsLoading(false);
      setIsBlur(false);
    } catch (error) {
      if (error.code === "auth/invalid-email") {
        setIsLoading(false);
        setIsBlur(false);
        setError("Invalid email. Please enter a valid email.");
      } else if (error.code === "auth/wrong-password") {
        setIsLoading(false);
        setIsBlur(false);
        setError("Invalid password. Please enter the correct password.");
      } else {
        console.error("Error logging in:", error);
        setIsLoading(false);
        setIsBlur(false);
        setError(error);
      }
    }
  };

  return (
    <motion.div
      initial={{ width: "100%", x: window.innerWidth }}
      animate={{ width: "100%", x: 0 }}
      exit={{ x: -window.innerWidth, transition: { duration: 3 } }}
    >
      <View style={styles.container}>
        <Navbar navigate={navigate} />

        <View style={styles.header}>
          {isBlur && <View style={styles.blurOverlay} />}

          {/* Place ActivityIndicator after blur overlay */}
          {isLoading && (
            <View style={styles.loaderContainer}>
              <ActivityIndicator size="large" color="green" />
            </View>
          )}
          <View
            style={
              isTablet
                ? styles.contentContainer
                : [styles.contentContainer, { flexDirection: "column" }]
            }
          >
            {" "}
            <View style={styles.imageContainer}>
              <Image
                source={require("../../assets/icon.png")}
                style={styles.image}
              />
            </View>
            <View style={styles.formContainer}>
              <Text style={styles.title}>Log In</Text>

              <View style={styles.inputContainer}>
                <TextInput
                  style={styles.input}
                  placeholder="Email"
                  keyboardType="email-address"
                  value={email}
                  onChangeText={(text) => setEmail(text)}
                />
                <TextInput
                  style={styles.input}
                  placeholder="Password"
                  secureTextEntry={true}
                  value={password}
                  onChangeText={(text) => setPassword(text)}
                />
              </View>

              {error ? <Text style={styles.error}>{error}</Text> : null}

              <Pressable
                style={[
                  styles.button,
                  isButtonDisabled && styles.disabledButton,
                ]}
                onPress={handleLogin}
                disabled={isButtonDisabled}
              >
                <Text style={styles.buttonText}>Log In</Text>
              </Pressable>

              <View style={styles.loginTextContainer}>
                <Text style={styles.loginText}>Need to create an account?</Text>
                <Pressable onPress={() => navigate("/signup")}>
                  <Text style={styles.loginLink}>Sign Up!</Text>
                </Pressable>
              </View>
            </View>
          </View>
        </View>
      </View>
    </motion.div>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: Dimensions.get("window").width,
    height: Dimensions.get("window").height,
    overflow: "hidden",
  },
  header: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    position: "relative",
  },
  contentContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "60%",
    position: "relative",
  },
  imageContainer: {
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 20,
    flex: 1,
  },
  image: {
    width: 150,
    height: 150,
    borderRadius: 10,
  },
  formContainer: {
    padding: 20,
    flex: 1,
  },
  inputContainer: {
    marginBottom: 20,
  },
  error: {
    color: "red",
    textAlign: "center",
    marginBottom: 10,
  },
  input: {
    height: 40,
    borderColor: "gray",
    borderWidth: 1,
    borderRadius: 10,
    marginBottom: 10,
    paddingHorizontal: 10,
  },
  buttonText: {
    color: "white",
    fontWeight: "bold",
  },
  loginTextContainer: {
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 10,
  },
  loginText: {
    color: "black",
  },
  loginLink: {
    color: "blue",
    textDecorationLine: "underline",
    marginLeft: 5,
  },
  button: {
    backgroundColor: "blue",
    padding: 10,
    borderRadius: 20,
    alignItems: "center",
    marginBottom: 20,
  },
  disabledButton: {
    backgroundColor: "grey",
  },
  blurOverlay: {
    position: "absolute",
    backgroundColor: "rgba(255, 255, 255, 0.5)", // Semi-transparent white
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1000,
    backdropFilter: "blur(8px)", // Apply blur effect
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 20,
    textAlign: "center",
  },
  loaderContainer: {
    position: "absolute",
    top: "50%",
    alignSelf: "center",
    zIndex: 1005,
  },
});
