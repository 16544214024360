import React, { useEffect } from "react";
import { View, Text, StyleSheet, ScrollView, Dimensions } from "react-native";
import { motion } from "framer-motion";
import Navbar from "../../Navbar";
import { useNavigate } from "react-router-dom";

const TermsAndPrivacy = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Terms"; // Change the title on component mount
  }, []); // Empty dependency array ensures the effect runs only once

  return (
    <motion.div
      initial={{ width: "100%", x: window.innerWidth }}
      animate={{ width: "100%", x: 0 }}
      exit={{ x: -window.innerWidth, transition: { duration: 3 } }}
    >
      <View style={styles.container}>
        <Navbar navigate={navigate} />
        <View style={styles.header}>
          <ScrollView contentContainerStyle={styles.container}>
            <View style={styles.section}>
              <Text style={styles.title}>Terms & Conditions</Text>
              <Text style={styles.text}>
                By accessing or using the Ummah Go app, you agree to abide by
                these terms and conditions.
                {"\n\n"}• Acceptance of Terms: Users agree to abide by these
                terms when using the app.
                {"\n\n"}• User Responsibilities: Users are responsible for the
                content they post and must not engage in unlawful activities.
                {"\n\n"}• Data Collection: Ummah Go collects and processes user
                data in compliance with the Privacy Policy.
                {"\n\n"}• Intellectual Property: All content and trademarks are
                owned by Ummah Go and must not be used without permission.
                {"\n\n"}• Limitation of Liability: Ummah Go is not liable for
                damages arising from app use.
                {"\n\n"}• Governing Law: These terms are governed by the laws of
                New Zealand.
              </Text>
            </View>
            <View style={styles.section}>
              <Text style={styles.title}>Privacy Policy</Text>
              <Text style={styles.text}>
                Ummah Go respects user privacy and collects and uses data as
                outlined below:
                {"\n\n"}• Information Collection: User-provided information and
                automatically collected data are used for app services.
                {"\n\n"}• Data Usage: User data is used for app improvement,
                analytics, and personalization.
                {"\n\n"}• Data Sharing: Third-party service providers may access
                data for operational purposes only.
                {"\n\n"}• Data Security: While Ummah Go employs security
                measures, absolute security is not guaranteed.
                {"\n\n"}• User Rights: Users have the right to access, rectify,
                or delete their data.
                {"\n\n"}• Policy Changes: Ummah Go reserves the right to update
                or modify the Privacy Policy.
              </Text>
            </View>
            <View style={styles.section}>
              <Text style={styles.title}>
                End User License Agreement (EULA)
              </Text>
              <Text style={styles.text}>
                Welcome to Ummah Go, a social media platform designed for
                Muslims. By using this app, you agree to the following terms:
                {"\n\n"}1. Content Guidelines:
                {"\n"}a. Nothing illegal, haram, or objectionable can be posted
                or discussed.
                {"\n"}b. No abusive, hateful, or discriminatory behavior or
                content is tolerated.
                {"\n"}c. Respect Islamic principles and values in your
                interactions.
                {"\n\n"}2. User Conduct:
                {"\n"}a. Treat others with kindness, respect, and dignity.
                {"\n"}b. Do not engage in harassment, bullying, or any form of
                hate speech.
                {"\n"}c. Ensure your interactions align with the teachings of
                Islam.
                {"\n\n"}3. Privacy and Safety:
                {"\n"}a. Safeguard your personal information and respect others'
                privacy.
                {"\n"}b. Do not share sensitive or confidential information.
                {"\n"}c. Report any abusive or inappropriate behavior promptly.
                {"\n\n"}4. Moderation and Enforcement:
                {"\n"}a. Ummah Go reserves the right to moderate and remove
                content that violates these terms.
                {"\n"}b. Repeated violations may result in suspension or
                termination of your account.
                {"\n\n"}Your use of Ummah Go signifies your acceptance of these
                terms.
              </Text>
            </View>
          </ScrollView>
        </View>
      </View>
    </motion.div>
  );
};

const styles = StyleSheet.create({
  container: {
    flexgr: 1,
    padding: 20,
  },
  section: {
    marginBottom: 20,
    alignItems: "center",
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 10,
    padding: 20,
  },
  text: {
    fontSize: 16,
    lineHeight: 24,
    textAlign: "center",
  },
  container: {
    flex: 1,
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },
  header: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    position: "relative",
  },
});

export default TermsAndPrivacy;
