import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  TextInput,
  Image,
  Pressable,
  Dimensions,
  ActivityIndicator,
  ScrollView,
} from "react-native";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  updateProfile,
} from "firebase/auth";
import firebase from "firebase/app";

import { auth } from "../../components/authentication/Firebase";
import { store } from "../../components/authentication/Firebase";
import {
  collection,
  query,
  where,
  getDocs,
  setDoc,
  doc,
  serverTimestamp,
} from "firebase/firestore";
import { motion } from "framer-motion";
import Navbar from "../../Navbar";
import { useNavigate } from "react-router-dom";

export default function SignUp() {
  const { width } = Dimensions.get("window");
  const isTablet = width >= 768; // Assume tablets for widths of 768 or greater

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isBlur, setIsBlur] = useState(false);
  const isButtonDisabled = !email || !password || !confirmPassword || !username;
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Sign Up"; // Change the title on component mount
  }, []); // Empty dependency array ensures the effect runs only once

  const addUserToFirestore = async (uid, username, email, imageUrl) => {
    try {
      const userDocRef = doc(store, "users", uid);

      // Set user data in the "users" collection
      await setDoc(userDocRef, {
        uid,
        username,
        email,
        bio: "No bio provided",
        emailVerified: false,
        profilePhoto: imageUrl,
        verified: false,
      });
    } catch (error) {
      console.error("Error adding user to Firestore:", error);
      // Handle error as needed
    }
  };

  const handleSignUp = async () => {
    setIsLoading(true);
    setIsBlur(true); // Activate blur effect

    // Validation checks
    try {
      if (
        username.includes(" ") ||
        username.length < 4 ||
        username.length > 12
      ) {
        setError("Username should be 4-12 characters without spaces.");
        isLoading;
        return;
      }

      if (password.length < 6) {
        setError("Password should be at least 6 characters long.");
        return;
      }

      if (password !== confirmPassword) {
        setError("Passwords do not match.");
        return;
      }

      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      const user = userCredential.user;

      //check if username exists
      const usersCollectionRef = collection(store, "users");
      const q = query(usersCollectionRef, where("username", "==", username));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        "username found " + querySnapshot.docs;
        setError("Username already exists");
        setIsLoading(false);
        user.delete();
        return;
      }

      await sendEmailVerification(user);
      const imageUrl =
        "https://firebasestorage.googleapis.com/v0/b/socialummah.appspot.com/o/default%2Fislamic.png?alt=media&token=8f8276b9-f780-4783-a86a-c99132e6b6b0"; // The URL of the profile image the user set during registration

      await addUserToFirestore(user.uid, username, email, imageUrl);
      const followedUserId = "JDIkmmOuCOPFfLE0pk8YSfOciq92";

      const followingRef = doc(
        store,
        `users/${user.uid}/following`,
        followedUserId
      );

      await setDoc(followingRef, {
        followedUserId,
        timestamp: serverTimestamp(),
      });

      const followerRef = doc(
        store,
        `users/${followedUserId}/followers`,
        user.uid
      );

      await setDoc(followerRef, {
        followerId: user.uid,
        timestamp: serverTimestamp(),
      });

      updateProfile(user, { displayName: username, photoURL: imageUrl });
      setUsername("");
      setEmail("");
      setPassword("");
      setConfirmPassword("");
      setError(""); // Clearing any previous errors on successful sign-up
    } catch (error) {
      setError("An error occurred. " + error); // Display error notification
      console.error("Error signing up:", error);
    } finally {
      setIsLoading(false);
      setIsBlur(false); // Deactivate blur effect
    }
  };

  return (
    <motion.div
      initial={{ width: "100%", x: window.innerWidth }}
      animate={{ width: "100%", x: 0 }}
      exit={{ x: -window.innerWidth, transition: { duration: 3 } }}
    >
      <ScrollView // Replace View with ScrollView
        style={styles.container} // Apply the container style
        contentContainerStyle={{ flexGrow: 1 }} // Ensure content can scroll
        keyboardShouldPersistTaps="handled" // Allow tapping outside text inputs to dismiss keyboard
      >
        <Navbar navigate={navigate} />
        <View style={styles.header}>
          {isLoading ? (
            <View style={styles.loaderContainer}>
              <ActivityIndicator size="large" color="green" />
            </View>
          ) : null}
          {isBlur && <View style={styles.blurOverlay} />}

          <View
            style={
              isTablet
                ? styles.contentContainer
                : [styles.contentContainer, { flexDirection: "column" }]
            }
          >
            <View style={styles.imageContainer}>
              <Image
                source={require("../../assets/icon.png")}
                style={styles.image}
              />
            </View>

            <View style={styles.formContainer}>
              <View style={styles.inputContainer}>
                <Text style={styles.title}>Sign Up</Text>

                <TextInput
                  style={styles.input}
                  placeholder="Username"
                  value={username}
                  onChangeText={(text) => setUsername(text)}
                />
                <TextInput
                  style={styles.input}
                  placeholder="Email"
                  inputMode="email-address"
                  value={email}
                  onChangeText={(text) => setEmail(text)}
                />
                <TextInput
                  style={styles.input}
                  placeholder="Password"
                  secureTextEntry={true}
                  value={password}
                  onChangeText={(text) => setPassword(text)}
                />
                <TextInput
                  style={styles.input}
                  placeholder="Confirm Password"
                  secureTextEntry={true}
                  value={confirmPassword}
                  onChangeText={(text) => setConfirmPassword(text)}
                />
              </View>
              {error ? <Text style={styles.error}>{error}</Text> : null}

              <Pressable
                style={[
                  styles.button,
                  isButtonDisabled && styles.disabledButton,
                ]}
                onPress={handleSignUp}
                disabled={isButtonDisabled}
              >
                <Text style={styles.buttonText}>Sign Up</Text>
              </Pressable>
              <Text style={styles.disclaimer}>
                By signing up you agree to our Terms & Conditions and Privacy &
                Policy
              </Text>
              <View style={styles.loginTextContainer}>
                <Text style={styles.loginText}>Already have an account? </Text>
                <Pressable onPress={() => navigate("/login")}>
                  <Text style={styles.loginLink}>Log In now!</Text>
                </Pressable>
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
    </motion.div>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: Dimensions.get("window").width,
    height: Dimensions.get("window").height,
    overflow: "hidden",
  },

  title: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 20,
    textAlign: "center",
  },
  imageContainer: {
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 20,
    flex: 1,
  },
  image: {
    width: 150,
    height: 150,
    borderRadius: 10,
  },
  formContainer: {
    padding: 20,
    flex: 1,
  },
  inputContainer: {
    marginBottom: 20,
  },
  contentContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "60%",
    position: "relative",
  },
  input: {
    height: 40,
    borderColor: "gray",
    borderWidth: 1,
    borderRadius: 10,
    marginBottom: 10,
    paddingHorizontal: 10,
  },
  button: {
    backgroundColor: "blue",
    padding: 10,
    borderRadius: 20,
    alignItems: "center",
    marginBottom: 20,
  },
  buttonText: {
    color: "white",
    fontWeight: "bold",
  },
  disclaimer: {
    color: "gray",
    fontSize: 12,
    textAlign: "center",
  },
  loginTextContainer: {
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 10,
  },
  loginText: {
    color: "black",
  },
  loginLink: {
    color: "blue",
    textDecorationLine: "underline",
    marginLeft: 5,
  },
  error: {
    color: "red",
    textAlign: "center",
    bottom: 10,
  },
  loaderContainer: {
    position: "absolute",
    top: "50%",
    alignSelf: "center",
    zIndex: 1005,
  },
  blurOverlay: {
    position: "absolute",
    backgroundColor: "rgba(255, 255, 255, 0.5)", // Semi-transparent white
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1000,
    backdropFilter: "blur(8px)", // Apply blur effect
  },
  disabledButton: {
    backgroundColor: "grey",
  },
  header: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    position: "relative",
  },
});
