import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  Image,
  Pressable,
  StyleSheet,
  Modal,
  Button,
} from "react-native";
import ReactPlayer from "react-player";

import Icon from "react-native-vector-icons/Ionicons";
import {
  doc,
  updateDoc,
  increment,
  getDoc,
  addDoc,
  getDocs,
  collection,
} from "firebase/firestore";
import { auth, store } from "../authentication/Firebase";
import { Alert } from "react-native";
import moment from "moment";
import PostDetails from "./PostDetails";
import { Ionicons } from "@expo/vector-icons"; // Import Ionicons from Expo
import PostMenu from "./PostMenu";
import { useNavigate } from "react-router-dom";

const Post = ({ tweet }) => {
  const [menuVisibility, setMenuVisibility] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const navigate = useNavigate();

  const hideMenu = () => {
    setMenuVisibility(false);
  };
  const [username, setUsername] = useState("");
  const [menuVisible, setMenuVisible] = useState(false);
  const [isVerified, setIsVerified] = useState(false); // New state for verification status

  const [profileImageUrl, setProfileImageUrl] = useState(null);
  const [isMuted, setIsMuted] = useState(true);
  const [isCurrentUserPost, setIsCurrentUserPost] = useState(false); // New state to track if the post is created by the current user
  const [commentCount, setCommentCount] = useState(0); // State to store the comment count
  const [isLiked, setIsLiked] = useState(false);
  const [likeCount, setLikeCount] = useState(0);

  const closeModal = () => {
    setModalVisible(false);
  };

  useEffect(() => {
    const incrementImpressionCount = async () => {
      try {
        // Increment the impression count in Firestore
        const postRef = doc(store, "posts", tweet.id);

        // Update impressions field by incrementing it by 1
        await updateDoc(postRef, {
          impressions: increment(1),
        });
      } catch (error) {
        console.error("Error incrementing impression count:", error);
      }
    };

    // Trigger the increment when the component mounts
    incrementImpressionCount();
  }, [tweet.id]);

  useEffect(() => {
    const checkLikedStatus = async () => {
      try {
        // Check if the current user has liked the post
        // Assuming tweet.id is the ID of the post and auth().currentUser.uid is the ID of the current user
        const likeDoc = await getDoc(
          doc(store, `posts/${tweet.id}/likes`, auth.currentUser.uid)
        );

        setIsLiked(likeDoc.exists());
      } catch (error) {
        console.error("Error checking liked status:", error);
      }
    };

    const fetchLikeCount = async () => {
      try {
        const likesSnapshot = await getDocs(
          collection(store, `posts/${tweet.id}/likes`)
        );

        setLikeCount(likesSnapshot.size);
      } catch (error) {
        console.error("Error fetching like count:", error);
      }
    };

    checkLikedStatus();
    fetchLikeCount();
  }, [tweet.id]);

  useEffect(() => {
    const checkLikedStatus = async () => {
      try {
        // Assuming tweet.id is the ID of the post and auth().currentUser.uid is the ID of the current user
        const likeDoc = await getDoc(
          doc(store, `posts/${tweet.id}/likes`, auth.currentUser.uid)
        );

        // Use likeDoc.exists() to check if the document exists
        if (likeDoc.exists()) {
          // Do something with the likeDoc
          setIsLiked(true);
        }
      } catch (error) {
        console.error("Error checking liked status:", error);
      }
    };

    checkLikedStatus();
  }, [tweet.id]);

  const handleLike = async () => {
    try {
      // Toggle the like status
      setIsLiked((prev) => !prev);

      // Add or remove the user's ID from the likes collection
      // Assuming tweet.id is the ID of the post and auth().currentUser.uid is the ID of the current user
      const likeDocRef = doc(
        store,
        `posts/${tweet.id}/likes`,
        auth.currentUser.uid
      );

      // Check if the post is already liked by the current user
      const likeDoc = await getDoc(likeDocRef);

      if (likeDoc.exists()) {
        // Post is already liked, so delete the like
        await deleteDoc(likeDocRef);
      } else {
        // Post is not liked, so set a like
        await setDoc(likeDocRef, {});
      }
    } catch (error) {
      console.error("Error toggling like:", error);
    }
  };

  useEffect(() => {
    // Fetch the username and profile image URL from Firestore based on createdBy
    const fetchUserData = async () => {
      try {
        const userDoc = await getDoc(doc(store, "users", tweet.createdBy));

        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUsername(userData.username);

          // Check if the user document contains a profilePhoto field
          if (userData.profilePhoto) {
            setProfileImageUrl(userData.profilePhoto);
          }
          userData.verified;
          setIsVerified(userData.verified || false);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    const fetchCommentCount = async () => {
      try {
        const commentsSnapshot = await getDocs(
          collection(store, `posts/${tweet.id}/comments`)
        );
        setCommentCount(commentsSnapshot.size);
      } catch (error) {
        console.error("Error fetching comment count:", error);
      }
    };

    const checkCurrentUserPost = () => {
      setIsCurrentUserPost(tweet.createdBy === auth.currentUser.uid);
    };

    fetchUserData();
    fetchCommentCount();
    checkCurrentUserPost();
  }, [tweet?.createdBy, tweet?.id]);

  const handlePress = () => {
    setModalVisible(true);
  };

  const handleProfileNavigate = () => {
    navigate("/profile", { state: { route: tweet.createdBy } }); // Navigate to the profile route with the userId
    hideMenu(); // Hide the menu when navigating to the user profile
  };

  const handleDelete = async () => {
    try {
      // Check if the current user is the post creator
      if (isCurrentUserPost) {
        // Show a confirmation alert before deleting
        Alert.alert(
          "Confirm Deletion",
          "Are you sure you want to delete this post?",
          [
            {
              text: "Cancel",
              style: "cancel",
            },
            {
              text: "Delete",
              onPress: async () => {
                try {
                  // Delete the post document using its ID (tweet.id)
                  await deleteDoc(doc(store, "posts", tweet.id));
                  // Add any additional logic or feedback for successful deletion
                  setMenuVisibility(false);
                } catch (error) {
                  console.error("Error deleting post:", error);
                  // Handle errors or display feedback for failed deletion
                }
              },
            },
          ]
        );
      } else {
        ("You are not authorized to delete this post.");
      }
    } catch (error) {
      console.error("Error deleting post:", error);
    }
  };
  const formatImpressions = (count) => {
    if (count < 1000) {
      return count.toString();
    } else if (count >= 1000 && count < 1000000) {
      return `${(count / 1000).toFixed(1)}K`;
    } else {
      return `${(count / 1000000).toFixed(1)}M`;
    }
  };

  return (
    <View
      style={{
        padding: 5,
        flexDirection: "row",
        borderBottomWidth: 1,
        borderBottomColor: "lightgray",
        width: "100%",
      }}
    >
      <Pressable
        onPress={() =>
          navigate("/profile", { state: { route: tweet.createdBy } })
        }
      >
        {/* Your existing code */}

        <Image source={{ uri: profileImageUrl }} style={styles.avatar} />
      </Pressable>

      <View style={styles.contentContainer}>
        <Pressable
          onPress={() =>
            navigate("/profile", { state: { route: tweet.createdBy } })
          }
        >
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Text style={styles.userName}>{username}</Text>
            {isVerified && (
              <Image
                source={require("../../assets/tick-mark.png")} // Adjust the path accordingly
                style={{ width: 16, height: 16, marginLeft: 5 }} // Adjust styling as needed
              />
            )}
          </View>
          <Text style={styles.timestamp}>
            {moment(tweet.timestamp.toDate()).fromNow()}
          </Text>
        </Pressable>
        <View style={styles.deleteIconContainer}>
          <PostMenu
            handleDelete={handleDelete}
            isCurrentUserPost={isCurrentUserPost}
            handleProfileNavigate={handleProfileNavigate}
            visible={menuVisibility} // Pass visibility as prop
            setVisible={setMenuVisibility} // Pass setter function
          />
        </View>
        <Pressable onPress={handlePress}>
          <Text style={styles.tweetText}>{tweet.tweet}</Text>

          {tweet.imageUrl && (
            <Image source={{ uri: tweet.imageUrl }} style={styles.tweetImage} />
          )}
        </Pressable>

        {tweet.videoUrl && (
          <ReactPlayer
            url={tweet.videoUrl}
            rate={1.0}
            volume={1.0}
            muted={true}
            controls={true}
            style={styles.tweetVideo}
          />
        )}
        <View style={styles.interactionContainer}>
          <Pressable style={styles.interactionButton} onPress={handleLike}>
            <Icon
              name={isLiked ? "heart" : "heart-outline"}
              size={23}
              color={isLiked ? "red" : "black"}
            />
            <Text style={styles.likeCount}>{likeCount}</Text>
          </Pressable>
          {/* <Pressable style={styles.interactionButton}>
            <Icon name="repeat-outline" size={23} color="green" />
          </Pressable> */}
          <Pressable style={styles.interactionButton} onPress={handlePress}>
            <Icon name="chatbox-outline" size={23} color="black" />
            <Text style={styles.commentCount}>{commentCount}</Text>
          </Pressable>
          <View style={styles.interactionButton}>
            <Text>{formatImpressions(tweet.impressions)} </Text>
            <Icon name="eye-outline" size={23} color="black" />
          </View>
        </View>
      </View>
      {/* Modal */}
      <Modal
        animationType="fade"
        transparent={true}
        visible={modalVisible}
        onRequestClose={closeModal}
      >
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <View
            style={{
              backgroundColor: "white",
              padding: 20,
              borderRadius: 10,
              width: "80%",
              height: "80%",
            }}
          >
            {/* Close button as Ionicon in the top right corner */}

            <Ionicons
              name="close-circle-outline"
              size={32}
              color="black"
              onPress={closeModal}
              style={{ position: "absolute", top: -20, right: -20 }}
            />

            {/* Your PostDetails component content */}
            <PostDetails route={tweet} />
          </View>
        </View>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  avatar: {
    width: 40,
    height: 40,
    borderRadius: 30,
    borderColor: "gray",
    borderWidth: 1,
  },
  contentContainer: {
    flex: 1,
    marginLeft: 10,
  },
  userName: {
    fontWeight: "bold",
    fontSize: 16,
  },
  tweetText: {
    marginTop: 5,
  },
  tweetImage: {
    width: 300,
    height: 250,
    marginTop: 10,
    resizeMode: "contain",
    alignSelf: "center", // Center horizontally
  },
  interactionContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 10,
    right: 20,
  },
  interactionButton: {
    flexDirection: "row",
    alignItems: "center",
  },
  tweetVideo: {
    width: 300,
    height: 200,
    alignSelf: "center", // Center horizontally
  },
  commentCount: {
    marginLeft: 5,
    color: "gray",
  },
  timestamp: {
    color: "gray",
    fontSize: 12,
  },
  deleteIconContainer: {
    position: "absolute",
    top: 0,
    right: 5,
    zIndex: 100, // Increase the zIndex value
    elevation: 1000, // Elevation for Android
  },
  likeCount: {
    marginLeft: 5,
    color: "gray",
  },
});

export default Post;
