import React, { useState, useEffect, useRef } from "react";
import {
  View,
  Text,
  Image,
  Pressable,
  StyleSheet,
  ScrollView,
  SafeAreaView,
  Alert,
  RefreshControl,
  ActivityIndicator,
  Modal,
} from "react-native";
import { Ionicons } from "@expo/vector-icons"; // Import Ionicons from Expo

import { store } from "../../components/authentication/Firebase";
import Post from "../../components/post/Post";
import { auth } from "../../components/authentication/Firebase";
import { useLocation, useNavigate } from "react-router-dom";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  where,
  deleteDoc,
  query,
  setDoc,
} from "firebase/firestore";

const UserProfile = () => {
  const { state } = useLocation();
  const { route } = state;
  const [userData, setUserData] = useState(null);
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [isFollowing, setIsFollowing] = useState(false);
  const [followersCount, setFollowersCount] = useState(0);
  const [followingCount, setFollowingCount] = useState(0);
  const [isVerified, setIsVerified] = useState(false);
  const [showMessagingModal, setShowMessagingModal] = useState(false); // State for messaging modal
  const navigate = useNavigate();

  useEffect(() => {
    document.title = userData?.username + "'s Profile"; // Change the title on component mount
  }, [userData]); // Empty dependency array ensures the effect runs only once

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userDocRef = doc(store, "users", route);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          const fetchedUserData = userDocSnap.data();
          setUserData(fetchedUserData);

          // Ensure userData exists before accessing its properties
          if (fetchedUserData) {
            setIsVerified(fetchedUserData.verified || false);
          }
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    const fetchUserPosts = async () => {
      try {
        const q = query(
          collection(store, "posts"),
          where("createdBy", "==", route),
          orderBy("timestamp", "desc")
        );

        const postsSnapshot = await getDocs(q);
        const userPosts = postsSnapshot.docs.map((doc) => doc.data());
        setPosts(userPosts);
      } catch (error) {
        console.error("Error fetching user posts:", error);
      } finally {
        setLoading(false);
      }
    };

    const checkIsFollowing = async () => {
      try {
        const followingDoc = await getDoc(
          doc(store, `users/${auth.currentUser.uid}/following`, route)
        );
        setIsFollowing(followingDoc.exists());
      } catch (error) {
        console.error("Error checking follow status:", error);
      }
    };

    const fetchFollowersCount = async () => {
      try {
        const followersSnapshot = await getDocs(
          collection(store, `users/${route}/followers`)
        );
        setFollowersCount(followersSnapshot.size);
      } catch (error) {
        console.error("Error fetching followers count:", error);
      }
    };

    const fetchFollowingCount = async () => {
      try {
        const followingSnapshot = await getDocs(
          collection(store, `users/${route}/following`)
        );
        setFollowingCount(followingSnapshot.size);
      } catch (error) {
        console.error("Error fetching following count:", error);
      }
    };

    fetchUserData();
    fetchUserPosts();
    checkIsFollowing();
    fetchFollowersCount();
    fetchFollowingCount();
  }, [route, refreshing]);
  const closeMessageModal = () => {
    setShowMessagingModal(false); // Close messaging modal
  };
  const handleRefresh = () => {
    setRefreshing(true);
  };

  const handleFollow = async () => {
    try {
      const userFollowingRef = collection(
        store,
        `users/${auth.currentUser.uid}/following`
      );
      const userFollowersRef = collection(store, `users/${route}/followers`);

      if (isFollowing) {
        await deleteDoc(doc(userFollowingRef, route));
        await deleteDoc(doc(userFollowersRef, auth.currentUser.uid));
      } else {
        await setDoc(doc(userFollowingRef, route), {});
        await setDoc(doc(userFollowersRef, auth.currentUser.uid), {});
      }

      setIsFollowing(!isFollowing);
    } catch (error) {
      console.error("Error toggling follow status:", error);
    }
  };

  if (!userData) {
    return (
      <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        <View
          style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
        >
          <ActivityIndicator size="small" color="#50C878" />
        </View>
      </View>
    );
  }
  return (
    <View style={{ flex: 1, backgroundColor: "#fff" }}>
      <Pressable
        onPress={() => navigate("/home")}
        style={{ position: "absolute", top: 0, left: 10, zIndex: 999 }}
      >
        <Ionicons name="arrow-back" size={48} color="black" />
      </Pressable>
      <Modal
        animationType="fade"
        transparent={true}
        visible={showMessagingModal}
        onRequestClose={closeMessageModal}
      >
        <View style={styles.modalContainer}>
          <View style={styles.modalMessageContent}>
            <Pressable style={styles.closeButton} onPress={closeMessageModal}>
              <Ionicons name="close" size={30} color="black" />
            </Pressable>
            <Text style={styles.modalText}>
              Messaging is disabled on web. Please download the app.
            </Text>
            <View style={styles.downloadContainer}>
              <Pressable>
                <Image
                  source={require("../../assets/appstore.png")}
                  style={styles.downloadIcon}
                />
              </Pressable>
              <Pressable>
                <Image
                  source={require("../../assets/playstore.png")}
                  style={styles.downloadIcon}
                />
              </Pressable>
            </View>
          </View>
        </View>
      </Modal>
      <ScrollView
        style={styles.container}
        contentContainerStyle={[
          styles.scrollViewContainer,
          {
            justifyContent: "center",
            alignItems: "center",
          },
        ]}
        showsVerticalScrollIndicator={false}
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={handleRefresh}
            tintColor="#50C878"
            colors={["#50C878"]}
          />
        }
      >
        <Image
          style={styles.userImg}
          source={{
            uri: userData.profilePhoto,
          }}
        />
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <Text style={styles.userName}>{userData?.username}</Text>
          {isVerified && (
            <Image
              source={require("../../assets/tick-mark.png")}
              style={{ width: 16, height: 16, marginLeft: 5 }}
            />
          )}
        </View>

        <Text style={styles.aboutUser}>
          {userData ? userData.bio || "No details added." : ""}
        </Text>
        <View style={styles.userBtnWrapper}>
          {route ? (
            <>
              {auth.currentUser && auth.currentUser.uid !== route && (
                <View style={styles.userBtnWrapper}>
                  <Pressable
                    style={styles.userBtn}
                    onPress={() => {
                      setShowMessagingModal(true);
                    }}
                  >
                    <Text style={styles.userBtnTxt}>Message</Text>
                  </Pressable>
                  <Pressable
                    style={[
                      styles.userBtn,
                      isFollowing && styles.followingBtn, // Apply green color when following
                    ]}
                    onPress={handleFollow}
                  >
                    <Text
                      style={[
                        styles.userBtnTxt,
                        isFollowing && styles.followingTxt,
                      ]}
                    >
                      {isFollowing ? "Following" : "Follow"}
                    </Text>
                  </Pressable>
                </View>
              )}
            </>
          ) : (
            <>
              <Pressable
                style={styles.userBtn}
                onPress={() => {
                  navigation.navigate("EditProfile");
                }}
              >
                <Text style={styles.userBtnTxt}>Edit</Text>
              </Pressable>
              <Pressable style={styles.userBtn} onPress={() => logout()}>
                <Text style={styles.userBtnTxt}>Logout</Text>
              </Pressable>
            </>
          )}
        </View>
        <View style={styles.userInfoWrapper}>
          <View style={styles.userInfoItem}>
            <Text style={styles.userInfoTitle}>{posts.length}</Text>
            <Text style={styles.userInfoSubTitle}>Posts</Text>
          </View>
          <Pressable
            onPress={() => navigation.navigate("FollowersList", { uid: route })}
          >
            <View style={styles.userInfoItem}>
              <Text style={styles.userInfoTitle}>{followersCount}</Text>
              <Text style={styles.userInfoSubTitle}>Followers</Text>
            </View>
          </Pressable>
          <Pressable
            onPress={() => navigation.navigate("FollowingList", { uid: route })}
          >
            <View style={styles.userInfoItem}>
              <Text style={styles.userInfoTitle}>{followingCount}</Text>
              <Text style={styles.userInfoSubTitle}>Following</Text>
            </View>
          </Pressable>
        </View>
        {posts.map((item) => (
          <Post key={`${item.id}`} tweet={item} />
        ))}
      </ScrollView>
    </View>
  );
};

export default UserProfile;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    padding: 5,
  },
  scrollViewContainer: {
    paddingBottom: 60, // Adjust this value to provide enough space for the tab bar
  },
  userImg: {
    height: 150,
    width: 150,
    borderRadius: 75,
  },
  userName: {
    fontSize: 18,
    fontWeight: "bold",
    marginTop: 10,
    marginBottom: 10,
  },
  aboutUser: {
    fontSize: 12,
    fontWeight: "600",
    color: "#666",
    textAlign: "left",
    marginBottom: 10,
    maxHeight: 80, // 12px font-size * 4 lines = 48px
    overflow: "hidden",
  },
  userBtnWrapper: {
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
    marginBottom: 10,
  },
  separator: {
    borderBottomWidth: 1,
    borderBottomColor: "gray",
    marginVertical: 2, // Adjust the space between the line and the content as needed
    width: "150%", // Ensure it spans the width of the container
  },

  userBtn: {
    borderColor: "#2e64e5",
    borderWidth: 2,
    borderRadius: 3,
    paddingVertical: 8,
    paddingHorizontal: 12,
    marginHorizontal: 5,
  },
  userBtnTxt: {
    color: "#2e64e5",
  },
  userInfoWrapper: {
    flexDirection: "row",
    justifyContent: "space-around",
    width: "100%",
    marginVertical: 20,
  },
  userInfoItem: {
    justifyContent: "center",
  },
  userInfoTitle: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 5,
    textAlign: "center",
  },
  userInfoSubTitle: {
    fontSize: 12,
    color: "#666",
    textAlign: "center",
  },
  followingBtn: {
    borderColor: "#27ae60", // Forest green border color when following
    backgroundColor: "#27ae60", // Forest green background when following
  },
  followingTxt: {
    color: "#fff", // White text when following
  },
  modalMessageContent: {
    backgroundColor: "white",
    padding: 20,
    borderRadius: 10,
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 1,
    borderColor: "black",
  },
  modalContent: {
    padding: 10,
    top: "50%",
    borderRadius: 10,
    alignItems: "center",
    justifyContent: "center",
  },
  modalText: {
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: 20,
  },
  closeButton: {
    position: "absolute",
    top: 10,
    right: 10,
    zIndex: 999, // Ensure it's above other elements
  },
  downloadContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    marginTop: 20,
  },
  downloadIcon: {
    top: -50,
    width: 200,
    height: 200,
    resizeMode: "contain",
    margin: 5, // Adjust the margin as needed
  },
});
