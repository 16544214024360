// Navbar.js
import React from "react";
import { View, Text, StyleSheet, Pressable, Image } from "react-native";

export default function Navbar({ navigate }) {
  return (
    <View style={styles.navbar}>
      <Image source={require("./assets/ummahgo.png")} style={styles.logo} />
      <View style={styles.links}>
        <Pressable onPress={() => navigate("/")}>
          <Text style={styles.link}>Home</Text>
        </Pressable>
        <Pressable onPress={() => navigate("/login")}>
          <Text style={styles.link}>Login</Text>
        </Pressable>
        <Pressable onPress={() => navigate("/terms")}>
          <Text style={styles.link}>Terms</Text>
        </Pressable>
        <Pressable onPress={() => navigate("/support")}>
          <Text style={styles.link}>Support</Text>
        </Pressable>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  navbar: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#333",
    width: "100%",
    flex: 1,
    maxHeight: "10%",
  },
  links: {
    flexDirection: "row",
  },
  link: {
    flexDirection: "row",
    color: "white",
    marginLeft: 20,
    marginRight: 10,
  },
  logo: {
    width: 150,
    height: 150,
    resizeMode: "contain",
  },
});
