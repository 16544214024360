import React from "react";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import Login from "../../pages/authentication/login";
import SignUp from "../../pages/authentication/signup";
import { AnimatePresence } from "framer-motion";
import Welcome from "../../pages/authentication/welcome";
import TermsAndPrivacy from "../../pages/authentication/terms";
import { StyleSheet } from "react-native";
import Support from "../../pages/authentication/support";

function AnimatedRoutes() {
  return (
    <BrowserRouter>
      <AnimatePresence mode="wait" initial={true}>
        <Routes>
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Welcome />} />
          <Route path="/terms" element={<TermsAndPrivacy />} />
          <Route path="/support" element={<Support />} />

          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </AnimatePresence>
    </BrowserRouter>
  );
}

export default AnimatedRoutes;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
  header: {
    alignItems: "center",
    justifyContent: "center",
    height: "10%",
  },

  content: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
});
