import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  StyleSheet,
  Alert,
  Animated,
  Pressable,
  Image,
} from "react-native";
import moment from "moment";
import { auth } from "../authentication/Firebase";
import { store } from "../authentication/Firebase";
import { useNavigate } from "react-router-dom";

import {
  getDoc,
  getDocs,
  query,
  collection,
  orderBy,
  doc,
} from "firebase/firestore";

const CommentsList = ({ postId, refreshComments, setRefreshComments }) => {
  const [comments, setComments] = useState([]);
  const [animationValues, setAnimationValues] = useState({}); // Store individual animation values
  const navigate = useNavigate();

  const fetchComments = async () => {
    try {
      const commentsQuery = query(
        collection(store, `posts/${postId}/comments`),
        orderBy("createdAt", "desc")
      );

      const commentsSnapshot = await getDocs(commentsQuery);

      const commentsData = [];
      for (const docRef of commentsSnapshot.docs) {
        const commentData = docRef.data();
        const userDocRef = doc(store, "users", commentData.createdBy);
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
          const userData = userDocSnapshot.data();
          const commentWithUserData = {
            id: docRef.id,
            ...commentData,
            username: userData.username,
            profilePhoto: userData.profilePhoto,
            verified: userData.verified,
          };
          commentsData.push(commentWithUserData);
        } else {
          const commentWithUnknownUser = {
            id: docRef.id,
            ...commentData,
            username: "Unknown",
            profilePhoto: null,
          };
          commentsData.push(commentWithUnknownUser);
        }
      }

      setComments(commentsData);
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };

  const handleLongPress = (comment) => {
    // Check if the comment belongs to the current user
    if (comment.createdBy === auth.currentUser.uid) {
      // Perform bounce animation if it's not the user's own comment
      const newAnimationValues = { ...animationValues };

      if (!newAnimationValues[comment.id]) {
        newAnimationValues[comment.id] = new Animated.Value(1);
      }
      // Start the animation for the specific comment

      Animated.sequence([
        Animated.timing(newAnimationValues[comment.id], {
          toValue: 1.1,
          duration: 100,
          useNativeDriver: true,
        }),
        Animated.spring(newAnimationValues[comment.id], {
          toValue: 1,
          friction: 3,
          useNativeDriver: true,
        }),
      ]).start();

      // Update the animation values in the state
      setAnimationValues(newAnimationValues);
      Alert.alert(
        "Delete Comment",
        "Are you sure you want to delete this comment?",
        [
          {
            text: "Cancel",
            style: "cancel",
          },
          {
            text: "Delete",
            onPress: async () => {
              try {
                await deleteDoc(
                  doc(store, `posts/${postId}/comments`, commentId)
                );
                setRefreshComments((prev) => !prev);
              } catch (error) {
                console.error("Error deleting comment:", error);
              }
            },
          },
        ]
      );
    } else {
      // Perform bounce animation if it's not the user's own comment
      const newAnimationValues = { ...animationValues };

      if (!newAnimationValues[comment.id]) {
        newAnimationValues[comment.id] = new Animated.Value(1);
      }
      // Start the animation for the specific comment
      Animated.sequence([
        Animated.timing(newAnimationValues[comment.id], {
          toValue: 1.1,
          duration: 100,
          useNativeDriver: true,
        }),
        Animated.spring(newAnimationValues[comment.id], {
          toValue: 1,
          friction: 3,
          useNativeDriver: true,
        }),
      ]).start();

      // Update the animation values in the state
      setAnimationValues(newAnimationValues);
    }
  };
  useEffect(() => {
    fetchComments();
  }, [postId, refreshComments]);

  return (
    <View style={styles.commentsContainer}>
      {comments.length === 0 ? (
        <View style={styles.noCommentsContainer}>
          <Text style={styles.noCommentsText}>No comments yet</Text>
        </View>
      ) : (
        comments.map((item) => (
          <Pressable
            key={item.id}
            onLongPress={() => handleLongPress(item)} // Long press handler
            onPress={() =>
              navigate("/profile", { state: { route: item.createdBy } })
            }
          >
            <Animated.View
              style={[
                styles.commentItem,
                {
                  transform: [
                    {
                      scale: animationValues[item.id] || new Animated.Value(1), // Apply individual scale transformation for each comment
                    },
                  ],
                },
              ]}
            >
              {item.profilePhoto && (
                <Image
                  source={{ uri: item.profilePhoto }}
                  style={styles.avatar}
                />
              )}
              <View style={styles.commentContent}>
                <View style={styles.commentHeader}>
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Text style={styles.userName}>{item.username}</Text>
                    {item.verified && (
                      <Image
                        source={require("../../assets/tick-mark.png")} // Adjust the path accordingly
                        style={{ width: 16, height: 16, marginLeft: 5 }} // Adjust styling as needed
                      />
                    )}
                  </View>
                  <Text style={styles.timestamp}>
                    {moment(item.createdAt.toDate()).fromNow()}
                  </Text>
                </View>
                <Text>{item.text}</Text>
              </View>
            </Animated.View>
          </Pressable>
        ))
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  commentsContainer: {
    marginTop: 10,
  },
  commentItem: {
    flexDirection: "row",
    alignItems: "flex-start",
    borderBottomWidth: 1,
    borderBottomColor: "lightgray",
    padding: 5,
  },
  avatar: {
    width: 30,
    height: 30,
    borderRadius: 15,
    marginRight: 10,
  },
  commentContent: {
    flex: 1,
  },
  commentHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 5,
  },
  username: {
    fontWeight: "bold",
    marginRight: 5,
  },
  timestamp: {
    color: "gray",
    fontSize: 12,
  },
  noCommentsContainer: {
    justifyContent: "center",
    alignItems: "center",
    minHeight: 70, // Adjust the height as needed
  },
  noCommentsText: {
    color: "gray",
    fontSize: 16,
  },
});

export default CommentsList;
