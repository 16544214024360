import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  Pressable,
  StyleSheet,
  TextInput,
  ScrollView,
  Alert,
  Keyboard,
  Image,
  RefreshControl,
} from "react-native";
import moment from "moment";
import Icon from "react-native-vector-icons/Ionicons";
import ReactPlayer from "react-player";

import { getDoc, doc, getDocs, collection, addDoc } from "firebase/firestore";
import { store } from "../authentication/Firebase";
import { auth } from "../authentication/Firebase";
import { useForm, Controller } from "react-hook-form";
import CommentsList from "./CommentsList";
import PostMenu from "./PostMenu";
import { useNavigate } from "react-router-dom";

const PostDetails = ({ route }) => {
  const { tweet } = route;
  const [refreshComments, setRefreshComments] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const navigate = useNavigate();

  const onRefresh = () => {
    setRefreshing(true);
    // Fetch comments or perform any necessary actions to refresh comments
    // Once comments are refreshed, setRefreshing to false
    setRefreshing(false);
  };

  const [menuVisibility, setMenuVisibility] = useState(false);
  const hideMenu = () => {
    setMenuVisibility(false);
  };
  const [username, setUsername] = useState("");
  const [profileImageUrl, setProfileImageUrl] = useState(null);
  const [isMuted, setIsMuted] = useState(true);
  const [isCurrentUserPost, setIsCurrentUserPost] = useState(false); // New state to track if the post is created by the current user
  const [commentCount, setCommentCount] = useState(0); // State to store the comment count
  const [isLiked, setIsLiked] = useState(false);
  const [likeCount, setLikeCount] = useState(0);

  const { control, handleSubmit, reset } = useForm();
  const [isVerified, setIsVerified] = useState(false); // New state for verification status

  useEffect(() => {
    // Fetch the username and profile image URL from Firestore based on createdBy
    const fetchUserData = async () => {
      try {
        const userDocRef = doc(store, "users", route.createdBy);

        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          setUsername(userData.username);
          setIsVerified(userData.verified);
          setProfileImageUrl(userData.profilePhoto);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    const fetchCommentCount = async () => {
      try {
        const commentsSnapshot = await getDocs(
          collection(store, `posts/${route.id}/comments`)
        );
        setCommentCount(commentsSnapshot.size);
      } catch (error) {
        console.error("Error fetching comment count:", error);
      }
    };

    const checkCurrentUserPost = () => {
      setIsCurrentUserPost(route.createdBy === auth.currentUser.uid);
    };

    fetchUserData();
    fetchCommentCount();
    checkCurrentUserPost();
  }, [route?.createdBy, route?.id]);

  useEffect(() => {
    const checkLikedStatus = async () => {
      try {
        const likeDoc = await getDoc(
          doc(store, `posts/${route.id}/likes`, auth.currentUser.uid)
        );
        setIsLiked(likeDoc.exists());
      } catch (error) {
        console.error("Error checking liked status:", error);
      }
    };

    const fetchLikeCount = async () => {
      try {
        const likesSnapshot = await getDocs(
          collection(store, `posts/${route.id}/likes`)
        );
        setLikeCount(likesSnapshot.size);
      } catch (error) {
        console.error("Error fetching like count:", error);
      }
    };

    checkLikedStatus();
    fetchLikeCount();
  }, [route.id]);

  const onSubmit = async (data) => {
    try {
      reset({ comment: "" });
      Keyboard.dismiss();

      await addDoc(collection(store, `posts/${route.id}/comments`), {
        text: data.comment,
        createdBy: auth.currentUser.uid,
        createdAt: new Date(),
      });

      reset({ comment: "" });
      setRefreshComments((prev) => !prev); // Toggle refreshComments state
    } catch (error) {
      console.error("Error adding comment:", error);
    }
  };
  const handleLike = async () => {
    try {
      // Toggle the like status
      setIsLiked((prev) => !prev);

      // Add or remove the user's ID from the likes collection
      const likeDocRef = doc(
        store,
        `posts/${route.id}/likes`,
        auth.currentUser.uid
      );

      if (isLiked) {
        await deleteDoc(likeDocRef);
      } else {
        await setDoc(likeDocRef, {});
      }
    } catch (error) {
      console.error("Error toggling like:", error);
    }
  };
  const handleDelete = async () => {
    try {
      // Check if the current user is the post creator
      if (isCurrentUserPost) {
        // Show a confirmation alert before deleting
        Alert.alert(
          "Confirm Deletion",
          "Are you sure you want to delete this post?",
          [
            {
              text: "Cancel",
              style: "cancel",
            },
            {
              text: "Delete",
              onPress: async () => {
                // Delete the post document
                await deleteDoc(doc(store, "posts", route.id));
                "Post deleted:", route.id;
                // Add any additional logic or feedback for successful deletion
                setMenuVisibility(false);
                navigate("/home");
              },
            },
          ]
        );
      } else {
        ("You are not authorized to delete this post.");
      }
    } catch (error) {
      console.error("Error deleting post:", error);
    }
  };

  const handleProfileNavigate = () => {
    navigate("/profile", { state: { route: route.createdBy } }); // Navigate to the profile route with the userId

    hideMenu(); // Hide the menu when navigating to the user profile
  };
  return (
    <ScrollView
      automaticallyAdjustKeyboardInsets={true}
      showsVerticalScrollIndicator={false}
      style={styles.container}
      contentContainerStyle={{ paddingBottom: 90 }}
      refreshControl={
        <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
      }
    >
      <Pressable
        onPress={() =>
          navigate("/profile", { state: { route: route.createdBy } })
        }
      >
        <View style={styles.header}>
          <Image source={{ uri: profileImageUrl }} style={styles.avatar} />
          <View>
            <Text style={styles.userName}>{username}</Text>
            <Text style={styles.timestamp}>
              {moment(route.timestamp.toDate()).fromNow()}
            </Text>
          </View>
          {isVerified && (
            <Image
              source={require("../../assets/tick-mark.png")} // Adjust the path accordingly
              style={{ width: 16, height: 16, marginLeft: -2, bottom: 8 }}
            />
          )}
        </View>
      </Pressable>
      <View style={styles.deleteIconContainer}>
        <PostMenu
          handleDelete={handleDelete}
          isCurrentUserPost={isCurrentUserPost}
          handleProfileNavigate={handleProfileNavigate}
          visible={menuVisibility} // Pass visibility as prop
          setVisible={setMenuVisibility} // Pass setter function
        />
      </View>

      <Text style={styles.tweetText}>{route.tweet}</Text>
      {route.imageUrl && (
        <Pressable
          onPress={() => {
            navigation.navigate("ShowImage", {
              url: route.imageUrl,
            });
          }}
        >
          <Image source={{ uri: route.imageUrl }} style={styles.tweetImage} />
        </Pressable>
      )}
      {route.videoUrl && (
        <ReactPlayer
          url={route.videoUrl}
          playing={true}
          controls={true}
          width="100%"
          height={400}
          muted={isMuted}
          config={{
            file: {
              attributes: {
                controlsList: "nodownload", // Remove download option
                disablePictureInPicture: true, // Disable picture-in-picture mode
              },
            },
          }}
        />
      )}

      <View style={styles.interactionContainer}>
        <Pressable style={styles.interactionButton} onPress={handleLike}>
          <Icon
            name={isLiked ? "heart" : "heart-outline"}
            size={23}
            color={isLiked ? "red" : "black"}
          />
          <Text style={styles.likeCount}>{likeCount}</Text>
        </Pressable>
        <Pressable style={styles.interactionButton}>
          <Icon name="chatbox-outline" size={23} color="black" />
          <Text style={styles.commentCount}>{commentCount}</Text>
        </Pressable>
        <View style={styles.interactionButton}>
          <Text>{route.impressions} </Text>
          <Icon name="eye-outline" size={23} color="black" />
        </View>
      </View>

      <View style={styles.commentContainer}>
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextInput
              placeholder="Comment"
              style={styles.commentInput}
              onBlur={onBlur}
              onChangeText={(text) => onChange(text)}
              value={value}
              multiline
              numberOfLines={4}
            />
          )}
          name="comment"
          rules={{ required: true }}
          defaultValue=""
        />
        <Pressable style={styles.submitButton} onPress={handleSubmit(onSubmit)}>
          {/* <FastImage name="send-outline" size={23} color="black" /> */}
          <Image
            source={require("../../assets/send-message.png")}
            style={{ width: 20, height: 20, marginLeft: 5 }}
          />
        </Pressable>
      </View>

      <CommentsList
        postId={route.id}
        refreshComments={refreshComments}
        setRefreshComments={setRefreshComments}
      />
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: "lightgray",
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
  },
  avatar: {
    width: 40,
    height: 40,
    borderRadius: 30,
    borderColor: "gray",
    borderWidth: 1,
  },
  userName: {
    marginLeft: 10,
    fontWeight: "bold",
    fontSize: 16,
  },
  tweetText: {
    marginTop: 10,
  },
  tweetImage: {
    width: "100%",
    height: 300,
    marginTop: 10,
    resizeMode: "contain",
  },
  interactionContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 10,
  },
  interactionButton: {
    flexDirection: "row",
    alignItems: "center",
  },
  tweetVideo: {
    width: "100%",
    height: 200,
    marginTop: 10,
  },
  commentContainer: {
    flexDirection: "row",
    marginTop: 10,
    alignItems: "center",
  },
  commentInput: {
    flex: 1,
    borderColor: "black",
    borderWidth: 1,
    borderRadius: 20,
    padding: 8,
  },
  submitButton: {
    marginLeft: 10,
  },
  commentsContainer: {
    marginTop: 10,
  },
  timestamp: {
    color: "gray",
    fontSize: 12,
    marginTop: 3, // Adjust spacing from the username
    marginLeft: 10,
  },
  deleteIconContainer: {
    position: "absolute",
    top: 0,
    right: 5,
    zIndex: 100, // Increase the zIndex value
    elevation: 1000, // Elevation for Android
  },
});

export default PostDetails;
