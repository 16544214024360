import React from "react";
import { View, Text, Pressable, StyleSheet, Image } from "react-native";
import { Ionicons } from "@expo/vector-icons"; // Import Ionicons or any icon library you prefer

export const NotificationCard = ({
  notificationText,
  onDelete,
  profilePhoto,
  username,
}) => {
  return (
    <View style={styles.cardContainer}>
      <View style={styles.userInfo}>
        {/* Profile Photo */}
        <Image source={{ uri: profilePhoto }} style={styles.profilePhoto} />
        {/* Username and Notification Text */}
        <View style={styles.userInfoText}>
          <Text style={styles.username}>{username}</Text>
          <Text numberOfLines={2} style={styles.notificationText}>
            {notificationText}
          </Text>
        </View>
      </View>
      {/* Trash Icon */}
      <Pressable style={styles.trashIcon} onPress={onDelete}>
        <Ionicons name="trash-outline" size={24} color="red" />
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  cardContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between", // Aligns children at each end of the container
    padding: 16,
    borderBottomWidth: 1,
    backgroundColor: "#fff",
    borderBottomColor: "#DDD",
    width: "40%",
    top: 50,
    borderRadius: 8,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    left: "60%",
  },
  userInfo: {
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
  },
  userInfoText: {
    marginLeft: 5,
  },
  username: {
    fontWeight: "bold",
  },
  notificationText: {
    marginTop: 4,
    width: "86%",
  },
  profilePhoto: {
    width: 40,
    height: 40,
    borderRadius: 20,
  },
  trashIcon: {
    marginLeft: "auto", // Moves the trash icon to the far right
  },
});
