import React, { useState, useEffect } from "react";
import { Menu, Provider, DefaultTheme } from "react-native-paper";
import { Pressable, View, Text } from "react-native";
import Icon from "react-native-vector-icons/Ionicons";

const PostMenu = ({
  handleDelete,
  isCurrentUserPost,
  handleProfileNavigate,
  visible: externalVisible, // External visibility prop
  setVisible: setExternalVisible, // Setter for external visibility
}) => {
  const [visible, setVisible] = useState(false);

  // Update internal visibility when the external prop changes
  useEffect(() => {
    setVisible(externalVisible);
  }, [externalVisible]);
  const openMenu = () => {
    setVisible(true);
    setExternalVisible(true); // Update external visibility
  };
  const closeMenu = () => {
    setVisible(false);
    setExternalVisible(false); // Update external visibility
  };
  const theme = {
    colors: {
      ...DefaultTheme.colors,
      surface: "#fff", // Set the surface color (background) to white
    },
  };

  return (
    <Provider>
      <View style={styles.menuContainer}>
        <Menu
          style={styles.menu} // Apply the 'menu' style here
          theme={theme}
          visible={visible}
          onDismiss={closeMenu}
          anchor={
            <Pressable onPress={openMenu}>
              {/* Use the button that triggers the menu */}
              <Icon name="ellipsis-vertical" size={23} color="black" />
            </Pressable>
          }
        >
          <Pressable onPress={handleProfileNavigate} style={styles.menuItem}>
            <Icon
              name="person-outline"
              size={17}
              color="black"
              style={styles.icon}
            />
            <Text style={styles.menuText}>Profile</Text>
          </Pressable>

          {isCurrentUserPost && <View style={styles.separator} />}

          {isCurrentUserPost && ( // Show the delete menu only if it's the current user's post
            <Pressable onPress={handleDelete} style={styles.menuItem}>
              <Icon
                name="trash-outline"
                size={17}
                color="red"
                style={styles.icon}
              />
              <Text style={styles.menuText}>Delete</Text>
            </Pressable>
          )}
        </Menu>
      </View>
    </Provider>
  );
};

const styles = {
  menuItem: {
    flexDirection: "row-reverse", // Change flexDirection to row-reverse
    alignItems: "center",
    paddingHorizontal: 8,
    paddingVertical: 5,
    justifyContent: "space-between", // Add justifyContent to space between items
    backgroundColor: "transparent", // Changed backgroundC to backgroundColor and set it to white
  },
  separator: {
    borderBottomWidth: 1,
    borderBottomColor: "gray",
    marginVertical: 2,
    width: "100%",
  },
  icon: {
    marginRight: 0,
  },
  menuText: {
    fontSize: 14,
  },
  menuContainer: {},
  menu: {
    top: 25,
    left: -100,
    width: 120,
    position: "absolute",
    elevation: 1000, // Increase the elevation value
    zIndex: 1000, // Add a high zIndex value
  },
};

export default PostMenu;
