import React, { useState, useEffect } from "react";
import { StatusBar } from "expo-status-bar";
import { StyleSheet, View, ActivityIndicator, Text } from "react-native";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./components/authentication/Firebase";

import AnimatedRoutes from "./components/routes/AnimatedRoutes";

import NavBar from "./Navbar";
import AppRoutes from "./components/routes/AppRoutes";

export default function App() {
  const [user, setAuthUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthUser(user);
      } else {
        setAuthUser(null);
      }
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  if (loading) {
    return (
      <View style={styles.container}>
        <ActivityIndicator size="large" color="#0000ff" />
        <StatusBar style="auto" />
      </View>
    );
  }

  return (
    <View style={styles.container}>
      {/* Conditionally render the header only for the signup page */}

      {/* Conditional rendering based on authentication */}
      {user ? (
        <AppRoutes />
      ) : (
        <View style={styles.content}>
          <AnimatedRoutes />
        </View>
      )}
      <StatusBar style="auto" />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
  header: {
    alignItems: "center",
    justifyContent: "center",
    height: "10%",
  },
  headerText: {
    fontSize: 40,
    fontWeight: "bold",
    fontFamily: "Roboto", // Change the font family to Arial (replace with desired font)
  },
  content: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
});
