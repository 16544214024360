import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  TextInput,
  ScrollView,
  Image,
  FlatList,
  RefreshControl,
  Button,
  ActivityIndicator,
  Pressable,
  Modal,
  TouchableWithoutFeedback,
} from "react-native";
import "firebase/auth";
import { store } from "../../components/authentication/Firebase";
import { auth } from "../../components/authentication/Firebase";
import Drawer from "@mui/joy/Drawer";
import List from "@mui/joy/List";
import Divider from "@mui/joy/Divider";
import ListItem from "@mui/joy/ListItem";
import ListItemButton from "@mui/joy/ListItemButton";
import { Ionicons } from "@expo/vector-icons"; // Import Ionicons from Expo

// Import your logo image
import LogoImage from "../../assets/Ummah.png"; // Update with your logo file path
import Post from "../../components/post/Post";
import {
  collection,
  getDocs,
  query,
  where,
  orderBy,
  limit,
  startAfter,
  doc,
  onSnapshot,
  deleteDoc,
  getDoc,
} from "firebase/firestore";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom"; // Import necessary hooks
import UserListItem from "../../components/UserListItem";
import { NotificationCard } from "../../components/NotificationCard";

const Home = () => {
  const [tweets, setTweets] = useState([]);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [lastTweet, setLastTweet] = useState({});
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [profilePhotoUrl, setProfilePhotoUrl] = useState("");
  const [fetchComplete, setFetchComplete] = useState(false);
  const [followerCount, setFollowerCount] = useState(0); // Add followerCount state
  const [isVerified, setIsVerified] = useState(false); // New state for verification status
  const [showPremiumPopup, setShowPremiumPopup] = useState(false);
  const [showMessagingModal, setShowMessagingModal] = useState(false); // State for messaging modal

  const [showNotifications, setShowNotifications] = useState(false);

  const [notifications, setNotifications] = useState([]);
  useEffect(() => {
    document.title = "Home"; // Change the title on component mount
  }, []); // Empty dependency array ensures the effect runs only once

  useEffect(() => {
    const userId = auth.currentUser.uid;
    const notificationsRef = collection(store, `users/${userId}/notifications`);

    const unsubscribe = onSnapshot(notificationsRef, (querySnapshot) => {
      const updatedNotifications = [];
      querySnapshot.forEach((doc) => {
        updatedNotifications.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      setNotifications(updatedNotifications);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const removeNotification = async (notificationId) => {
    try {
      const userId = auth.currentUser.uid;
      const notificationsRef = doc(
        store,
        `users/${userId}/notifications`,
        notificationId
      );

      await deleteDoc(notificationsRef);
    } catch (error) {
      console.error("Error deleting notification:", error);
    }
  };

  const openNotifications = () => {
    setShowNotifications(true);
  };

  const closeNotifications = () => {
    setShowNotifications(false);
  };

  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const handleSearch = async (text) => {
    setSearchQuery(text);

    try {
      if (text.trim() === "") {
        setSearchResults([]); // Clear results if the search text is empty
        return;
      }

      const usersRef = collection(store, "users");
      const querySnapshot = await getDocs(usersRef);

      const data = [];
      querySnapshot.forEach((doc) => {
        const username = doc.data().username.toLowerCase();
        if (username.includes(text.toLowerCase())) {
          data.push({ id: doc.id, ...doc.data() });
        }
      });

      setSearchResults(data);
    } catch (error) {
      console.error("Error searching users:", error);
    }
  };

  const fetchTweets = async () => {
    try {
      if (isFetchingMore) {
        return;
      }
      setIsFetchingMore(true);

      const currentUser = auth.currentUser;
      if (!currentUser) {
        return;
      }

      // Get the list of users the current user follows
      const followingRef = collection(
        store,
        `users/${currentUser.uid}/following`
      );

      const followingSnapshot = await getDocs(followingRef);

      const followingUserIds = [];
      followingSnapshot.forEach((doc) => {
        followingUserIds.push(doc.id);
      });

      // Fetch posts by users the current user follows
      const tweetsCollection = collection(store, `posts`);
      const fetchedTweets = [];

      // Loop through each followed user and fetch their posts

      for (const userId of followingUserIds) {
        const lastDoc = lastTweet[userId]; // Assuming lastTweet[userId] holds the last document

        const q = query(
          tweetsCollection,
          where("createdBy", "==", userId),
          orderBy("timestamp", "desc"),
          limit(3)
        );
        let modifiedQuery = q; // Create a new variable to hold the modified query

        if (lastDoc) {
          modifiedQuery = query(
            tweetsCollection,
            where("createdBy", "==", userId),
            orderBy("timestamp", "desc"),
            limit(3),
            startAfter(lastDoc)
          );
        }
        const snapshot = await getDocs(modifiedQuery);

        snapshot.forEach((doc) => {
          fetchedTweets.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        if (snapshot.docs.length > 0) {
          // Store the last tweet for each user to paginate
          lastTweet[userId] = snapshot.docs[snapshot.docs.length - 1];
        }
      }

      // Combine and update the fetched tweets
      if (fetchedTweets.length > 0) {
        const uniqueTweets = fetchedTweets.filter(
          (tweet, index, self) =>
            index === self.findIndex((t) => t.id === tweet.id)
        );

        // Sort uniqueTweets by timestamp in descending order
        uniqueTweets.sort((tweetA, tweetB) => {
          const timestampA = tweetA.timestamp.toDate(); // Convert Firestore Timestamp to JavaScript Date
          const timestampB = tweetB.timestamp.toDate(); // Convert Firestore Timestamp to JavaScript Date

          // Sort in descending order based on timestamp
          return timestampB - timestampA;
        });

        // Update tweets state by combining new and existing tweets
        setTweets((prevTweets) => [...prevTweets, ...uniqueTweets]);
      }
    } catch (error) {
      console.error("Error fetching tweets:", error);
    } finally {
      setIsFetchingMore(false);
    }
  };

  const onEndReached = () => {
    if (!isFetchingMore) {
      fetchTweets();
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const user = auth.currentUser;

        const userDocRef = doc(store, "users", user.uid);

        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          setUsername(userData.username);
          setIsVerified(userData.verified);
          setProfilePhotoUrl(userData.profilePhoto);
        } else {
          ("No such document!");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setFetchComplete(true);
      }
    };

    fetchUserData();
  }, []);
  RefreshControl;
  const [open, setOpen] = React.useState(false);

  const handleListItemClick = (text) => {
    if (text === "Home" && location.pathname === "/home") {
      setOpen(false); // Close drawer if already on the "Home" page
    }
    if (text === "Notifications" && location.pathname === "/home") {
      setOpen(false); // Close drawer if already on the "Home" page
      setShowNotifications(true);
      setShowMessagingModal(false); // Show messaging modal
    } else if (text === "Messages") {
      setOpen(false);

      setShowMessagingModal(true); // Show messaging modal
    } else {
      // Logic for other ListItemButton clicks goes here
      // For example: Navigate to different pages based on 'text'
      // history.push('/some-other-page');
    }
  };
  const closeMessageModal = () => {
    setShowMessagingModal(false); // Close messaging modal
  };

  const toggleDrawer = (inOpen) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(inOpen);
  };

  return (
    <View style={styles.container}>
      <Modal
        animationType="fade"
        transparent={true}
        visible={showMessagingModal}
        onRequestClose={closeMessageModal}
      >
        <View style={styles.modalContainer}>
          <View style={styles.modalMessageContent}>
            <Pressable style={styles.closeButton} onPress={closeMessageModal}>
              <Ionicons name="close" size={30} color="black" />
            </Pressable>
            <Text style={styles.modalText}>
              This feature is disabled on web. Please download the app.
            </Text>
            <View style={styles.downloadContainer}>
              <Pressable>
                <Image
                  source={require("../../assets/appstore.png")}
                  style={styles.downloadIcon}
                />
              </Pressable>
              <Pressable>
                <Image
                  source={require("../../assets/playstore.png")}
                  style={styles.downloadIcon}
                />
              </Pressable>
            </View>
          </View>
        </View>
      </Modal>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        <View style={styles.drawerHeader}>
          {/* Profile Icon */}
          <Image source={profilePhotoUrl} style={styles.profileIcon} />

          {/* Username */}
          <Text style={styles.profileName}>{username}</Text>
        </View>

        <Divider />

        <List>
          {["Home", "Notifications", "Messages"].map((text) => (
            <ListItem key={text} onClick={() => handleListItemClick(text)}>
              <ListItemButton>{text}</ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
        <View style={styles.bottomButtons}>
          <List>
            <ListItemButton
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                color: "black",
              }}
              onClick={() => {
                setOpen(false);
                setShowMessagingModal(true);
              }}
            >
              <span>Settings</span>
              <Ionicons name="cog" size={24} color="black" />
            </ListItemButton>
            <ListItemButton
              onClick={async () => {
                await auth.signOut();
              }}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                color: "red",
                "&:hover": {
                  color: "red", // Ensures color remains red on hover
                },
              }}
            >
              <span>Logout</span>
              <Ionicons name="log-out" size={24} color="red" />
            </ListItemButton>
          </List>
        </View>
      </Drawer>

      {/* Header */}
      <Modal
        visible={showNotifications}
        transparent={true}
        animationType="fade"
        onRequestClose={closeNotifications}
      >
        <TouchableWithoutFeedback onPress={closeNotifications}>
          <View style={styles.modalOverlay}>
            <View style={styles.modalContent}>
              {notifications.length === 0 ? (
                <View style={styles.emptyContainer}>
                  <Text style={styles.message}>You're all caught up!</Text>
                </View>
              ) : (
                notifications.map((notification) => (
                  <NotificationCard
                    key={notification.postId}
                    notificationText={notification.text}
                    profilePhoto={notification.profilePhoto}
                    onDelete={() => removeNotification(notification.postId)}
                    username={notification.username}
                  />
                ))
              )}
            </View>
          </View>
        </TouchableWithoutFeedback>
      </Modal>
      <View style={styles.header}>
        {/* Logo */}
        <Pressable
          style={({ pressed }) => [{ opacity: pressed ? 0.5 : 1.0 }]}
          onPress={toggleDrawer(true)}
        >
          <Ionicons name="menu" size={24} color="black" />
        </Pressable>

        {/* Search input bar */}
        <TextInput
          style={styles.searchInput}
          placeholder="Search..."
          value={searchQuery}
          onChangeText={(text) => handleSearch(text)}
        />
        <FlatList
          data={searchResults.slice(0, 5)} // Limits to a maximum of 5 elements
          renderItem={({ item, index }) => (
            <UserListItem
              key={`${item.id}_${index}`}
              user={item}
              onPress={() => {
                //navigate(`/profile/${item.uid}`); // Navigate to the profile route with the userId
                navigate("/profile", { state: { route: item.uid } }); // Navigate to the profile route with the userId
              }}
            />
          )}
          keyExtractor={(item, index) => `${item.id}_${index}`}
          style={styles.flatListContainer}
        />

        {/* Three icons on the right */}
        <View style={styles.iconContainer}>
          {/* Use Ionicons */}
          <Pressable
            style={styles.icon}
            onPress={() => {
              setShowMessagingModal(true); // Show messaging modal
            }}
          >
            <Ionicons name="chatbox" size={24} color="black" />
          </Pressable>
          <Pressable style={styles.icon} onPress={openNotifications}>
            <Ionicons name="ios-notifications" size={24} color="black" />
          </Pressable>
        </View>
      </View>

      <View style={styles.containerBody}>
        <FlatList
          style={styles.mainContent}
          stickyHeaderIndices={[0]}
          data={tweets}
          renderItem={({ item }) => <Post tweet={item} />}
          keyExtractor={(item, index) => `${item.id}_${index}`}
          onEndReached={onEndReached}
          contentContainerStyle={{ paddingBottom: 79 }}
          // refreshControl={
          //   <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
          // }
          ListFooterComponent={() =>
            isFetchingMore && tweets.length > 0 ? (
              <ActivityIndicator size="large" color="#50C878" />
            ) : null
          }
          ListEmptyComponent={() =>
            !isFetchingMore && tweets.length === 0 ? (
              <View style={styles.emptyListContainer}>
                <ActivityIndicator
                  size="large"
                  color="#50C878"
                  style={{ top: 50 }}
                />
              </View>
            ) : null
          }
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // Add any other styles you need
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 16,
    paddingVertical: 10,
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
    position: "sticky",
    top: 0,
    zIndex: 1,
    backgroundColor: "#fff",
  },
  logo: {
    width: 50,
    height: 50,
    resizeMode: "contain",
  },
  searchInput: {
    width: "40%",
    height: 40,
    borderWidth: 1,
    borderRadius: 5,
    paddingHorizontal: 10,
    marginLeft: 10,
  },
  iconContainer: {
    flexDirection: "row",
  },
  icon: {
    marginLeft: 30,
  },
  mainContent: {
    flex: 1,
  },
  headerSpacer: {
    height: 70, // Adjust the height to match your header height
  },
  headerItem: {
    marginBottom: 10,
  },
  headerText: {
    fontSize: 16,
  },
  rightSide: {
    flex: 1,
    backgroundColor: "#fff",
    height: "80%",
  },
  containerBody: {
    flex: 1,
  },
  emptyListContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 20,
  },
  emptyContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  emptyText: {
    fontSize: 18,
    color: "#555",
    marginTop: 20,
  },
  drawerHeader: {
    flexDirection: "row",
    alignItems: "center",
    padding: 10,
  },
  profileIcon: {
    width: 50,
    height: 50,
    borderRadius: 25,
    marginRight: 10,
  },
  profileName: {
    fontWeight: "bold",
    fontSize: 16,
  },
  bottomButtons: {
    marginBottom: 10, // Adjust this value for spacing from the bottom
  },
  flatListContainer: {
    position: "absolute",
    top: 50, // Adjust these values as needed
    left: "26.5%",
    right: 10,
    zIndex: 999, // Adjust the z-index if needed
    backgroundColor: "white", // Background color if desired
    width: "40%",
    borderRadius: 8,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    marginBottom: 20,
  },
  username: {
    // Any styles for the username Text component
  },
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background for the modal
  },
  modalMessageContent: {
    backgroundColor: "white",
    padding: 20,
    borderRadius: 10,
    width: "80%",
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 1,
    borderColor: "black",
  },
  modalContent: {
    padding: 10,
    borderRadius: 10,
    width: "77%",
    alignItems: "center",
    justifyContent: "center",
  },
  modalText: {
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: 20,
  },
  closeButton: {
    position: "absolute",
    top: 10,
    right: 10,
    zIndex: 999, // Ensure it's above other elements
  },
  downloadContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    marginTop: 20,
  },
  downloadIcon: {
    width: 200,
    height: 100,
    resizeMode: "contain",
  },
});

export default Home;
