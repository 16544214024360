import React, { useEffect } from "react";
import { View, Text, StyleSheet, ScrollView, Dimensions } from "react-native";
import { motion } from "framer-motion";
import Navbar from "../../Navbar";
import { useNavigate } from "react-router-dom";

const Support = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Contact Us"; // Change the title on component mount
  }, []); // Empty dependency array ensures the effect runs only once

  return (
    <View style={styles.container}>
      <Navbar navigate={navigate} />
      <View style={styles.header}>
        <View>
          <View style={styles.section}>
            <Text style={styles.title}>Contact Us</Text>
            <Text style={styles.text}>
              Need help or support?{"\n\n"}
              Contact us at:{"\n"}
              Email: apphalalgo@gmail.com{"\n"}
              Phone: +64211476089{"\n"}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 400, // You can adjust this value to remove the gap
  },
  section: {
    marginBottom: 500,
    alignItems: "center",
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 10,
    padding: 20,
  },
  text: {
    fontSize: 16,
    lineHeight: 24,
    textAlign: "center",
  },
  container: {
    flex: 1,
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },
  header: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    position: "relative",
  },
});

export default Support;
