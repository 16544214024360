import React from "react";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Home from "../../pages/loggedin/home";
import PostDetails from "../post/PostDetails";
import UserProfile from "../../pages/loggedin/userprofile";

function AppRoutes() {
  return (
    <AnimatePresence mode="wait" initial={true}>
      <BrowserRouter>
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="*" element={<Navigate to="/home" />} />
          <Route path="/profile" element={<UserProfile />} />
        </Routes>
      </BrowserRouter>
    </AnimatePresence>
  );
}

export default AppRoutes;
