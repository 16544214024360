import React, { useEffect } from "react";
import { View, Text, StyleSheet, Pressable, Button } from "react-native";
import { Video } from "expo-av";
import { useNavigate } from "react-router-dom";
import Navbar from "../../Navbar";

export default function Welcome() {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Ummah Go";
  }, []);

  return (
    <View style={styles.container}>
      <Navbar navigate={navigate} />
      <View style={styles.header}>
        <Video
          shouldPlay={true}
          isLooping={true}
          isMuted={true}
          style={styles.video}
          controls={false}
          resizeMode="cover"
          source={require("../../assets/background2.mp4")}
        />
        <View style={styles.content}>
          <Text style={styles.title}>
            Ummah Go: Connecting Muslims worldwide
          </Text>
          <Text style={styles.subtitle}>
            Join millions of vibrant Muslim communities worldwide, embracing
            {"\n"}
            connections to share unforgettable moments, heartfelt thoughts, and
            so
            {"\n"}
            much more!
          </Text>
          <View style={styles.button}>
            <Button
              title="Connect Now"
              color="#50C878"
              onPress={() => navigate("/login")}
            />
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },
  header: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    position: "relative",
  },
  video: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 0,
  },
  content: {
    position: "absolute",
    top: "20%",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    paddingHorizontal: 20,
    paddingBottom: 50,
    zIndex: 1,
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    textAlign: "center",
    color: "white",
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 16,
    color: "white",
    textAlign: "center",
    marginBottom: 30,
  },
  button: {
    marginTop: 20,
    borderRadius: 15,
    overflow: "hidden",
    shadowColor: "#000",
    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 3,
  },
});
